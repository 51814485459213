// ==========================================//
// Modals
// ==========================================//

import $ from 'jquery';
import Embed from './embedVideo';

const $body = $('body');
let scrollPos;

function scrollLock(toggle) {
	if (toggle == 'on') {
		scrollPos = $(window).scrollTop();
		setTimeout(function(){
			$body.find('> :not(.js-modal)').addClass('js-hide');
			$(window).scrollTop(0);
		}, 300);
	}else {
		$body.find('> :not(.js-modal)').removeClass('js-hide');
		$(window).scrollTop(scrollPos);
		setTimeout(function(){
			$(window).trigger('resize');
		}, 10);
	}
}

function toggleModal($el){
	// Closes the modal
	if ($el.hasClass('js-active')) {
		closeModal($el);
	}
	// Opens the modal
	else {
		openModal($el);
		$body.on('keyup', function(e){
			if (e.originalEvent.keyCode === 27) {
				closeModal($el);
			}
		});
	}
}

function closeModal($el = $('.js-modal'), transition = 300) {
	scrollLock('off');
	$el.removeClass('js-active');
	setTimeout(function(){
		$body.unbind('keyup');
		$el.css({
			display: 'none'
		});
	}, 300);
}

function openModal($el){
	scrollLock('on');
	$el.css({
		display: 'block'
	});
	setTimeout(function(){
		$el.addClass('js-active');
	}, 50);
}

// ==================================================================
// Search Form

const $searchModal = $('.js-search-modal');
const $searchToggle  = $('.js-search-toggle');
let searchClick = 1;

$searchToggle.on('click', function(e){
	e.preventDefault();
	if (searchClick) {
		searchClick = 0;

		toggleModal($searchModal);

		if ($(this).hasClass('js-focusForm')) {
			$searchModal.find('[type="text"]').focus();
		}

		setTimeout(function(){
			searchClick = 1;
		}, 500);
	}
});

// ==================================================================
// Mobile Nav

const $mobileNav = $('.js-mobile-nav');
const $hamburger  = $('.js-mobile-nav-toggle');
let hamburgerClick = 1;

$hamburger.on('click', function(e){
	e.preventDefault();
	if (hamburgerClick) {
		hamburgerClick = 0;

		toggleModal($mobileNav);

		setTimeout(function(){
			hamburgerClick = 1;
		}, 500);
	}
});

// ==================================================================
// Modal Videos

function random(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

$body.on('click', '.js-video-modal a.js-play', function(e){
	e.preventDefault();
	let $modal;
	let ranNum = random(1000,10000);
	let videoEmbed = new Embed($(this).attr('href'), { autoplay: 1 });
	let modalTemplate = `<div class="js-modal ${ranNum}">
		<button class="js-close"></button>
		<div class="alignContent">
			<div class="verticalAlign">
				<div class="row">
					<div class="content column">
						<div class="responsiveEmbed">
							${videoEmbed.render()}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>`;

	$body.append(modalTemplate);

	$modal = $('.js-modal.'+ranNum);

	console.log($modal);
	
	$modal.css({
		display: 'block',
	});

	setTimeout(function(){
		$modal.addClass('js-active');
	}, 300);
	
	$body.on('click', '.js-modal.'+ranNum, function(e){
		let $this = $(this);

		$this.removeClass('js-active');

		setTimeout(function(){
			$this.remove();
		}, 300);
	})
});