/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
const slick = require('slick-carousel');

// ==========================================//
// Slick Slider with SCSS Float Grid
// ==========================================//

const getBreakpoints = function($slider){

	// Apply breakpoint classes to .js-slider
	// Works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
	let breakpoints = {init: 0,xxs: 480,xs: 540,sm: 640,md: 768,xmd: 968,lg: 1024,xl: 1280,xxl: 1440};
	let responsiveBreakpoints = [];
	let classBreakpoints = $slider[0].className.split(' ');

	classBreakpoints.forEach(function (value) {
		if(value.indexOf(`-up-`) > -1){
			let breakpoint = value.split('-')[0];
			let slides = parseInt(value.split('-').pop().trim());
			responsiveBreakpoints.push({breakpoint: breakpoints[breakpoint],settings: {slidesToShow: slides,slidesToScroll: slides}});
		}
	});

}

$('.js-slider').each(function(){
	let $slider = $(this);

	$slider.slick({
		// Options
		dots: false,
		arrows: true,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		mobileFirst: true,
		prevArrow:`<button class="slick-left"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l7 7-7 7" stroke="#211F20" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
		nextArrow:`<button class="slick-right pull-right"><svg width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l7 7-7 7" stroke="#211F20" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
		responsive: getBreakpoints($slider)
	});
});

$('.js-slider--banner').each(function(){
	let $slider = $(this);

	$slider.slick({
		// Options
		dots: false,
		arrows: false,
		speed: 500,
		fade: true,
		cssEase: 'linear',
		mobileFirst: true,
		autoplay: true, 
		autoplaySpeed: 5000,
		responsive: getBreakpoints($slider)
	});
});


