import $ from 'jquery';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

(function($) {

    $.fn.equalize = function(buffer = 500) {

        var $window = $(window);
        var targetArray = this.data('equalize').replace(' ', '').split(',');
        var currentHeight = 0;
        var widthChanged = 1;

        this.inView = function() {
            return ($window.scrollTop() + $window.height() >= this.offset().top && $window.scrollTop() <= this.offset().top + this.height());
        }

        this.matchHeight = function($el, identifier) {
            // Initialise at 0
            currentHeight = 0;

            if ($el.length) {
                // All targets to show real height
                $el.css('height', 'auto');

                // Test each target height against initial height
                for (var i = 0; i < $el.length; i++) {
                    if ($el.eq(i).height() > currentHeight) {
                        currentHeight = $el.eq(i).height();
                    }
                }

                // Set all targets to have equal height
                $el.height(currentHeight);
            }else {
                console.warn('Equalizer error: There was no target found with the identifier "' + identifier + '".', $el);
            }
        }

        this.equalizeTargets = function() {
            for (var i = 0; i < targetArray.length; i++) {
                this.matchHeight(this.find('[data-equalize-watch="'+targetArray[i]+'"]'), targetArray[i]);
                // this.matchRows(this.find('[data-equalize-watch="'+targetArray[i]+'"]'), targetArray[i]);
            }
        }

        this.matchRows = function($el, identifier) {
            var row = [];
            var currentOffset = $el.first().offset().top;
            var currentHeight = 0;
            var totalItems = $el.length;
            
            $el.each(function(index) {
                var $thisEl = $(this);

                console.log(index, totalItems - 1);

                if ($thisEl.offset().top !== currentOffset || index == totalItems - 1) {
                    // update current offset
                    currentOffset = $thisEl.offset().top;

                    // All targets to show real height
                    for (var i = 0; i < row.length; i++) {
                        row[i].css('height', 'auto');
                    }

                    for (var i = 0; i < row.length; i++) {
                        if (row[i].height() > currentHeight) {
                            currentHeight = row[i].height();
                        }
                        // row[i].height();
                    }

                    for (var i = 0; i < row.length; i++) {
                        row[i].height(currentHeight);
                    }

                    row = [];
                }

                row.push($thisEl);
            })
        }

        this.init = function() {
            
            // Initialise Function
            this.equalizeTargets();

            // On scroll event
            $window.on('scroll', throttle(() => {
                if (widthChanged && this.inView()) {
                    // Tell function width has no longer changed
                    widthChanged = 0;
                    this.equalizeTargets();
                }
            }, buffer));

            // On resize event
            $window.on('resize', debounce(() => {
                // Tell function width has changed
                widthChanged = 1;
                if (this.inView()) {
                    this.equalizeTargets();
                }
            }, buffer));
        }

        this.init();
    }

})($);


$('[data-equalize]').each(function(){
    $(this).equalize();
});
// console.log($('[data-equalize]').first());
// $('[data-equalize]').equalize()