import $ from 'jquery';
// import throttle from 'lodash';
import debounce from 'lodash/debounce';

// ==========================================//
// Filters
// ==========================================//


function moveClass(myClass, $from, $to) {
	$from.removeClass(myClass);
	$to.addClass(myClass);
}

$('.js-filters').each(function () {
	const $group = $(this);
	const category = parseInt($group.data('category-id'));
	const $filter = $group.find('.js-filters-link');
	const apiURL = $group.data('api-url');
	const $content = $group.find('.js-ajax-content');
	const $loadMore = $group.find('.js-load-more-button');
	let canFilter = 1;
	let ajaxURL = apiURL;
	let ID = '';

	if (category) {
		$filter.removeClass('js-active');

		let $currentFilter = $group.find(`[data-filter-id="${category}"]`);
		ID = $currentFilter.data('filter-id');
		ajaxURL = `${apiURL}?ID=${ID}`;
		$currentFilter.addClass('js-active');
	}

	const filterItems = function(apply = false) {
		$.ajax({
			url: ajaxURL,
			dataType: "json",
			success: function (result) {
				let items = result.data.items;
				let showMore = result.data.show_more;

				if (apply) {
					$content.height($content.height());
					
					$content.css({
						opacity: 0
					});

					setTimeout(function () {

						if (items.length >= 1) {
							setTimeout(function(){
								$content.html('');

								for (let i = 0; i < items.length; i++) {
									// console.log();
									$content.append($(items[i].html))
								}
								
								// equalize($group.find('[data-equalize]'));
								$group.find('[data-equalize]').equalize();
							})
						} else {
							$content.html(`
								<div class="text-center">
									<div class="column">
										<h3 class="colour--primary">Unfortunately there are no items found for the current filter.</h3>
									</div>
								</div>
							`);
						}

						setTimeout(function(){
							adjustHeight($content);
						}, 100)

						setTimeout(function () {
							// TweenLite.to($content, 0.3, { autoAlpha: 1 });
							canFilter = 1;

							$content.css({
								opacity: 1
							})

							if (showMore) {
								$loadMore.removeClass('disabled');
							} else {
								$loadMore.addClass('disabled');
							}
						}, 300)

					}, 300);
				}else {
					if (!showMore) {
						$loadMore.addClass('disabled');
					}
				}

			}
		});
	}

	const loadMore = function(load = false) {
		$.ajax({
			url: ajaxURL,
			dataType: "json",
			success: function (result) {
				let items = result.data.items;
				let showMore = result.data.show_more;

				if (load) {
					$content.height($content.height());

					setTimeout(function () {
						if (items.length >= 1) {

							for (let i = 0; i < items.length; i++) {
								$content.append($(items[i].html))
							}

							// equalize($group.find('[data-equalize]'));
							$group.find('[data-equalize]').equalize();

							// $loadMore.removeClass('disabled');
							setTimeout(function(){
								adjustHeight($content);
							}, 100)
						}

						canFilter = 1;

					}, 300);
				}

				if (!showMore) {
					$loadMore.addClass('disabled');
				}
			}
		});
	}

	$filter.each(function () {
		let $this = $(this);

		$this.on('click', function (e) {
			e.preventDefault();

			if ($this.data('filter-id') != undefined) {
				ID = $this.data('filter-id');
				ajaxURL = `${apiURL}?ID=${ID}`;
			} else {
				ajaxURL = apiURL;
				ID = '';
			}

			// Wait for animations
			if (canFilter == 1 & !$this.hasClass('js-active')) {
				canFilter = 0;
				// Set active state onto new filter
				moveClass('js-active', $filter, $this);

				filterItems(true);
			}
		})
	})

	$loadMore.on('click', function (e) {
		e.preventDefault();

		if (!$(this).hasClass('disabled')) {
			let $item = $group.find('.js-ajax-item');
			let offset = $item.length;

			ajaxURL = `${apiURL}?ID=${ID}&offset=${offset}`;

			loadMore(true);
		}
	});

	// filterItems();
	loadMore();
})

function adjustHeight($element) {
	let currentHeight = $element.height();
	let newHeight;

	$element.css({
		'height': 'auto'
	});

	newHeight = $element.height();

	$element.css({
		'height': currentHeight
	});

	setTimeout(function(){
		$element.css({
			'height': newHeight
		});
	}, 50);


	// TweenLite.to($element, 0.5, { height: newHeight, ease: Power2.easeInOut });
}


$(window).on('resize', debounce(function () {
	adjustHeight($('.js-ajax-content'));
}, 1000));