/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';

const $jsAspect = $('.js-aspect');

$jsAspect.each(function(){
	let $this = $(this);
	let ratio = $this.data('aspect').split(',');
	let width;

	let setWidth = function() {
		width = $this.height() * (ratio[0] / ratio[1]);
		$this.width(width);
	}

	$(window).on('resize', _.throttle(function(){
		setWidth();
	}, 50))

	setWidth();
})