// Requirements
const $  = require('jquery');
const _  = require('lodash');
const GM = require('google-maps');

$(document).ready(function(){

    let $body = $('body');

    // if ($body.data('maps-api-key') != undefined) {

        GM.KEY = $body.data('maps-api-key');
        $('.js-map').each(function(){
            let $container = $(this)
            let $mapArea = $container.find('.js-map-frame');
            let $location = $container.find('.js-map-location');

            let map;
            let marker;
            let center = {
                lat: parseFloat($location.first().data('location-lat')),
                lng: parseFloat($location.first().data('location-lng')) - .05,
            };

            GM.load(function(google) {

                // Initialise the map
                map = new google.maps.Map($mapArea[0], {
                    center: center,
                    disableDefaultUI: true,
                    scrollwheel: false,
                    zoomControl: true,
                    zoom: 12,
                });

                $location.each(function(){
                    let $thisLocation = $(this);
                    marker = new google.maps.Marker({
                        map: map,
                        position: {
                            lat: parseFloat($thisLocation.data('location-lat')),
                            lng: parseFloat($thisLocation.data('location-lng')),
                        },
                    });
                });
            });
        });
    // }
});
