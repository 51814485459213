import $ from 'jquery';
import Shuffle from 'shufflejs';

// ==========================================//
// Masonry
// ==========================================//

let shuffleInstance;

$('.js-testimonials').each(function() {
	let $container = $(this);
	let $group = $container.find('.js-testimonials-group');
	let $items = $container.find('.js-testimonials-item');
	let $load = $container.find('.js-load-more-button');
	let ajaxURL = $container.data('api-url');
	let offset = $items.length;
	let $newItem;

	const shuffle = function(parent, item) {
		shuffleInstance = new Shuffle(parent, {
			itemSelector: item,
			sizer: item,
		});

		$items = $container.find('.js-testimonials-item');
		offset = $items.length;
	}

	const loadItems = function(load = false){
		$.ajax({
			url: `${ajaxURL}?offset=${offset}`,
			dataType: 'JSON',
			success: function(result){
				let showMore = result.data.show_more;

				if (load) {
					for (var i = 0; i < result.data.items.length; i++) {
						$group.append($(result.data.items[i].html));
					}

					shuffleInstance.destroy();

					shuffle($group[0], '.js-testimonials-item');
				}

				if (!showMore) {
					$load.addClass('disabled');
				}
			}
		})
	}

	shuffle($group[0], '.js-testimonials-item');

	loadItems();

	$load.on('click', function(){
		if (!$(this).hasClass('disabled')) {
			loadItems(true);
		}
	})
})